.form-container {
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0,);
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        background-color: rgba(255, 255, 255); /* Przezroczyste tło */
        display:flex;
        justify-content: center;
        align-items: center;

      
  }
  
  form > div {
    margin-bottom: 10px;
  }
  
  label {
    margin-right: 10px;
  }
  
 
  
  .form-container > form > div> button, .form-container > form > button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .linknazwa{
    background-color: #618a63;
    padding: 20px;
  }
  