/* General styles for product grid item */
.product-grid-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.3vw;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Single product item styles */
.product-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative; /* Add this line */
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Product thumbnail styles */
.product-item img {
  width: 100%;
  height: 40vw;
  display: block;
}

/* Product title styles */
.product-item-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px;
  color: #333;
}

/* Product description styles */
.product-item-description {
  font-size: 0.9em;
  color: #666;
  margin: 0 10px 10px;
}

/* Product price styles */
.product-item-price {
  font-size: 1.1em;
  font-weight: bold;
  color: #28a745;
  margin: 10px;
}

/* Product button styles */
.product-item-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1em;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.product-item-button:hover {
  background-color: #0056b3;
}

.product-info {
  margin-top: 5px;
}

.expanded-links {
  position: absolute;
  top: 0;
  left: 0; /* Adjust to align the overlay as needed */
  width: 100%; /* Full width overlay */
  height: 100%; /* Full height overlay */
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.additional-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
}

.additional-link-item {
  
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 60%;
}

.additional-link-item img {
  width: 80%;
  height: 30px;
  margin-right: auto auto;
}

/* Additional responsive styles */
@media (max-width: 1000px) {
  .product-grid-item {
    flex-direction: column;
    gap: 15px;
  }

  .product-item {
    width: 46vw;
  }
}

@media (min-width: 1000px) {
  .product-grid-item {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  gap: 0.3vw;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  .product-grid-item {
    flex-direction: row;
    gap: 20px;
  }

  .product-item {
    width: 100%;
  aspect-ratio: 1 / 1.2; /* Sprawia, że wysokość = szerokość (1:1) */
  object-fit: cover;
  }
  .product-thumbnail{
    height: 20px;

  }
  /* Product thumbnail styles */
.product-item img {
  width: 100%;
  height: 80%;
  display: block;
}
.additional-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10vw;
}
}
