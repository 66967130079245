@media only screen and (max-width: 1000px) {
.promo-code-container {
  display: flex;
  align-items: center;
  background-color: #FFA500; /* Assuming an orange background */
  color: #ffffff;
  
  border-radius: 10px;
  width: 100vw;
  height: 120px;
  font-family: Arial, sans-serif;
  
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin: 10px 0 10px 0;
}
.promo-logo-container{
  width: 20vw;
  height: 60%;
  justify-content: top;
}
.promo-company-logo {
  width: 70px; /* Adjust according to actual size needed */
  height: 70px;
  margin-left: 10px;
  margin-right: 10px;
}

.promo-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.promo-description {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  justify-content: center;
  
}

.promo-code-section {
  display: flex;
  align-items: center;
  background-color: #ECE0E0;
  color: #000;
  padding: 0;
  width: 40vw;
  margin-left: auto;
  margin-right: 30vw;
  border-radius: 24px;
  margin-bottom: 10px;
  height: 4.5vh;
}

.promo-code {
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
}

.copy-button {
  background: #bab8b8;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust for better visual compatibility */
  color: #000;
  
  border-radius: 5px 24px 24px 5px;
  height: 4.5vh;
  width: 30%;
  
}
.copy-button > img{
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0.5vh;
}
.copyImg{
  margin-left: 10%;
  margin-right: 10%;
}

.copy-button:hover {
  color: #007BFF;
}

.shop-button {
  display: flex;
  background-color: #ffffff;
  color: #fff;
  border: 2px dotted #ffffff;
  border-radius: 11px;
  
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 50vw;
  margin-left: 5vw;
  margin-right: 20vw;
  justify-content: center;
}
.promo-details > a {
  width: 50vw;
}
.shop-button:hover {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #000;
}
.PromoButton {
  height: 50px;
  width: 80vw;
  background-color: #28A2E5;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  box-shadow: 3px 3px 3px grey;
  font-size: 40px;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
  color: #fff;
  position: relative; /* Dodane aby móc pozycjonować elementy w środku */
}

.discountImg {
  position: absolute;
  width: 50px; /* Używanie vw zamiast px dla responsywności */
  top: 100%;
  left: -5%; /* Lekko poza kontenerem */
  transform: translateY(-50%); /* Wyśrodkowanie pionowe */
}
}
@media only screen and (min-width: 1000px) {
  .promo-code-container {
    display: flex;
    align-items: center;
    background-color: #FFA500; /* Assuming an orange background */
    color: #ffffff;
    
    border-radius: 10px;
    width: 60vw;
    height: 120px;
    font-family: Arial, sans-serif;
    
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    margin: 10px 0 10px 0;
    margin-left: auto;
    margin-right: auto;
  }
  .promo-logo-container{
    width: 20vw;
    height: 60%;
    justify-content: top;
  }
  .promo-company-logo {
    width: 70px; /* Adjust according to actual size needed */
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .promo-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .promo-description {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    
  }
  
  .promo-code-section {
    display: flex;
    align-items: center;
    background-color: #ECE0E0;
    color: #000;
    padding: 0;
    width: 30vw;
    margin-left: auto;
    margin-right: 15vw;
    border-radius: 24px;
    margin-bottom: 10px;
    height: 4.5vh;
  }
  
  .promo-code {
    flex-grow: 1;
    font-size: 18px;
    font-weight: bold;
  }
  
  .copy-button {
    background: #bab8b8;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust for better visual compatibility */
    color: #000;
    margin-right: 0;
    border-radius: 5px 24px 24px 5px;
    height: 4.5vh;
    width: 30%;
    
  }
  .copy-button > img{
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5vh;
  }
  
  .copy-button:hover {
    color: #007BFF;
  }
  
  .shop-button {
    display: flex;
    background-color: #ffffff;
    color: #fff;
    border: 2px dotted #ffffff;
    border-radius: 11px;
    
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 30vw;
    margin-left: auto;
    margin-right: 15vw;
    justify-content: center;
  }
  
  .shop-button:hover {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #000;
  }
  .PromoButton {
    font-size: 0;
  }
  
  .discountImg {
    position: absolute;
    width: 50px; /* Używanie vw zamiast px dla responsywności */
    top: 100%;
    left: -5%; /* Lekko poza kontenerem */
    transform: translateY(-50%); /* Wyśrodkowanie pionowe */
  }
  }