
@media only screen and (max-width: 1000px){
  .App {
    text-align: center;
    max-width:100vw;
    font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  }
  a{
    text-decoration: none;
  }
  .App-header {
    background-image: url(../public/polska.jpeg);
    background-size: cover;
    background-position: center;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .search-bar input {
    padding: 15px 25px 15px 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #0099FF;
    background-color: #FFFFFF;
    width: 70%;
    height: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, border 0.3s, box-shadow 0.3s;
  }
  
  .search-bar input:focus {
    background-color: #F0F8FF;
    border: 2px solid #0066CC;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    outline: none;
  }
  
  .search-bar button {
    padding: 10px;
    margin-left: -60px; /* Adjust the position to align with input */
    background: transparent;
    border: none;
    cursor: pointer;
    padding-top: 13px;
  }
  
  .search-bar button img {
    width: 34px;
    height: 34px;
    background-color: #0099FF;
    border-radius: 25px;
    padding: 5px;
  }
  
  .search-bar button:hover {
    opacity: 0.8;
  }
  

  .categories {
    display: flex;
    overflow-x: auto; /* Umożliwia przewijanie w poziomie, jeśli przyciski wychodzą poza kontener */
  }
  .category-button {
    padding: 0;
    margin: 10px;
    font-size: large;
    
    border-color: rgba(0, 0, 0, 0.06);
    background-color: rgba(240, 35, 35, 0.06);
    width:50px;
    border-radius: 10px;
    height: 50px;
    
  }

  .playlistBigLogo{
    width:100%;
    height: 100%;
  }
  .temuButton{
    background-image: url(logotemu.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fe7a04;
    min-width: 30vw;
  }
  .aliButton{
    background-image: url(aliexpress.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e32d06;
    min-width: 30vw;
  }
  .amazonkaTopkaButton{
    background-image: url(amazonkatopkakategoria.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 30vw;
  }
  .homecategory{
    background-image: url(homecategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .findscategory{
    background-image: url(znaleziskaamazonki.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .kidscategory{
    background-image: url(kidscategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .petscategory{
    background-image: url(petscategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .category-button > span {
   color: black;
   font-size: 100px;
  }
  .play-pause-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7em;
    color: white;
    cursor: pointer;
  }
  .category-button > span{
    bottom:7px;
    font-size: 20px;
    position: relative;
  }
  .category-button > img{
    margin-left: 0px;
    right:7px;
    top:3px;
    max-width:32px;
    max-height: 32px;
    position: relative;
  }
  .hrstyle{
    color: #D9D9D9;
    margin-top: 12px;
    margin-bottom: 0;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0vw;
    
  }
  .product{
    width:33vw;
    height:60.2vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0.1vh 0.1vw 0vh 0.1vw;
  }
  /* Dodaj więcej stylów według potrzeb */
  .full-screen-div-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Przezroczyste tło */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-screen-div-content {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
    border-radius: 10px;
    text-align: center;
    width:100vw;
    height: 100vh;
    z-index: 1;
  }
  .full-screen-div-content > video{
    z-index: 1;
  }
  
  .back-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top:3%;
    right: 35%;
    z-index: 2;
  }
  
  .back-button:hover {
    background-color: #45a049;
  }
  .expanded-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .expanded-list li {
    margin-bottom: 10px;
  }
  
  .bottom-button {
    background-color: #28a1e5;
    color: white;
    padding: 0px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    position: fixed;
    bottom: 2%;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    height: auto;
    min-height: 60px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .bottom-button:hover {
    transform: translateX(-50%) scale(1.05);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .bottom-button-topdiv {
    height: 20px;
    width: 100%;
    background-color: #28a1e5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bottom-button-expand {
    width: 20px;
    height: 20px;
    transform: rotate(180deg) scale(2);
    filter: invert(100%);
    
  }
  
  .bottom-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .linkImg {
    height: 30px;
    width: auto;
    margin-right: 10px;
    filter: invert(100%);
  }
  
  .bottom-button-expand-text {
    flex-grow: 1;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: white;
    justify-content: center;
  }


  /* Dodane style dla pomarańczowego diva */
  .orange-div {
    width: 100vw;
    height: 50vh;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%;
    
    
    overflow:scroll;
    z-index: 100;
  }
/* Ustawienie siatki dla link-item */
.expanded-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Przerwa między elementami */
}

.link-item {
  width: calc(50% - 5px); /* Ustalamy szerokość na 50% z uwzględnieniem przerwy */
  height: 250px; /* Ustawiamy stałą wysokość */
  box-sizing: border-box; /* Uwzględnia padding i border w szerokości */
  padding: 5px;
  position: relative;
}

.link-container {
  height: 100%; /* Pełna wysokość link-item */
  width: 100%; /* Pełna szerokość link-item */
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: column;
  align-items: center; /* Wyrównuje zawartość do środka */
  box-sizing: border-box;
  overflow: hidden; /* Ukrywa nadmiar zawartości */
}

.link-container > img {
  width: 100%; /* Pełna szerokość w obrębie link-container */
  height: 100%; /* Pełna wysokość w obrębie link-container */
  max-height: 200px;
  object-fit: cover; /* Przycina obrazek i dopasowuje go do kontenera */
}

.link-text {
  width: 100%; /* Pełna szerokość w obrębie link-container */
  text-align: center; /* Wyrównuje tekst do środka */
  color: #213801;
  font-size: 1.5rem; /* Możesz dostosować rozmiar czcionki w razie potrzeby */
  padding: 10px; /* Dodaje trochę paddingu */
  box-sizing: border-box; /* Uwzględnia padding w szerokości */
  justify-content: center;
}
.linkiHeader {
  height: 40px;
  max-width: 100%;
  position: sticky;
  top: 0; /* Przykleja do górnej krawędzi kontenera */
  background-color: #FFFFFF; /* Ustawienie tła, aby było widoczne na wszystkich tła */
  z-index: 101; /* Aby upewnić się, że jest nad innymi elementami w .orange-div */
  border-bottom: 1px solid #ddd; /* Opcjonalne: Dodaje cienką linię u dołu, aby oddzielić nagłówek od reszty */
}

  
  .company-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .link-text {
    display: flex;
    align-items: center;
  }
  .link-text > text {
    left: 10%;
  }
  
  .company-logo {
    width: 24px; /* dostosuj szerokość do preferencji */
    height: 24px; /* dostosuj wysokość do preferencji */
    margin-right: 8px; /* odstęp między logo a tekstem */
  }
    
  .x{
    text-align: center;
    width:10%;
    float: left;
    padding-top: 2%;
    
    height: 10px;
  }
  .x > img{
    width:50px;
    height:50px;
    position: absolute;
    right: 44%;
    top: 0px;
    transform: scale(1.5);
  }
  .xus{
    width: 40px;
    height: 40px;
  }
  
  
  .full-screen-div-content>video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  .socialsIcons{
    height:20vh;
    width: 40px;
    position:absolute;
    top:0px;
    right: 6px;
  }
  .socialIcon{
    height: calc(20vh / 4 - 7px);
    width: calc(20vh / 4 - 7px);
    margin: 3px 0 0 3px;
  }
  .spinner-container {
    position: fixed;
    bottom: 20px;      /* 20px od dołu ekranu */
    left: 50%;         /* wyśrodkuj w poziomie */
    transform: translateX(-50%); 
    z-index: 9999;     /* żeby było ponad innymi elementami */
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;        /* kolor obwódki */
    border-top-color: #28a1e5;     /* kolor części obręczy */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .active-category {
    border: 3px solid #FFC107; /* albo inny kolor */
    /* Możesz dać background, np. background-color: #FFFACD; */
  }
  /* Overlay na in-app browser */
.inapp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* 
    UWAGA:
    Teraz overlay ma onClick w kodzie => 
    klikniecie w puste tlo zamknie overlay. 
  */
}

.inapp-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 10px;

  /* 
    onClick={(e) => e.stopPropagation()} w kodzie 
    sprawia, że kliknięcia w .inapp-content 
    nie zamkną overlay
  */
}

.inapp-overlay-img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Guzik X w prawym górnym rogu */
.close-inapp {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: #e74c3c; 
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  line-height: 1;
}

/* Dodatkowy div z napisem "Zamknij" */
.close-text {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  
  color: #000;
  padding: 8px 20px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  width: 30vw;
  height: 4vh;
}

  
}


@media only screen and (min-width: 1000px) {
  .App {
    text-align: center;
    max-width: 100vw;
  }
  a{
    text-decoration: none;
  }
  .App-header {
    background-image: url(../public/upscaleamazonkalong.png);
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #28a1e5;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .search-bar input {
    padding: 15px 25px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #0099FF;
    background-color: #FFFFFF;
    width: 25%;
    height: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, border 0.3s, box-shadow 0.3s;
  }
  
  .search-bar input:focus {
    background-color: #F0F8FF;
    border: 2px solid #0066CC;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    outline: none;
  }
  
  .search-bar button {
    margin-left: -55px; /* Adjust the position to align with input */
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .search-bar button img {
    width: 34px;
    height: 34px;
    background-color: #0099FF;
    padding: 5px;
    border-radius: 25px;
    margin-top: 3px;
  }
  .linkiHeader{
    width:0px;
    height: 0px;
  }
  
  .xus{
    width: 0px;
    height: 0px;
  }

  .categories {
    display: flex;
    overflow-x: auto; /* Umożliwia przewijanie w poziomie, jeśli przyciski wychodzą poza kontener */
  }

  .playlistBigLogo {
    width:100%;
    height: 100%;
  }
  
  .temuButton{
    background-image: url(logotemu.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fe7a04;
  }

  .aliButton{
    background-image: url(aliexpress.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e32d06;
  }

  .category-button {
    padding: 10px;
    margin: 10px;
    font-size: 10px;
    width: 20vw;
    height: 100px;
    border-color: rgba(0, 0, 0, 0.06);
    background-color: rgba(240, 35, 35, 0.06);
    border-radius: 10px;
  }

  .temuButton{
    background-image: url(logotemu.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fe7a04;
    min-width: 20vw;
  }
  .aliButton{
    background-image: url(aliexpress.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #e32d06;
    min-width: 20vw;
  }
  .amazonkaTopkaButton{
    background-image: url(amazonkatopkakategoria.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 20vw;
  }
  .homecategory{
    background-image: url(homecategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .findscategory{
    background-image: url(znaleziskaamazonki.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .kidscategory{
    background-image: url(kidscategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }
  .petscategory{
    background-image: url(petscategory.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#2d9ee4;
    min-width: 70px;
  }

  .category-button > span {
    bottom:7px;
    font-size: 20px;
    position: relative;
  }
  .category-button > img {
    margin-left: 0px;
    right:3px;
    top:3px;
    max-width:32px;
    max-height: 32px;
    position: relative;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.3vw;
  }

  .product {
    width:19.5vw;
    height:35vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2vh 0.1vw 2vh 0.1vw;
  }

  /*
    -------------------------------
    FULL-SCREEN OVERLAY I ZAWARTOŚĆ
    -------------------------------
  */
  .full-screen-div-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 999;
  }

  /* 
    Kontener wideo + orange-div, 
    szerokość 80vw, a w środku: 30vw dla wideo i 50vw dla panelu
  */
  .full-screen-div-content {
    display: flex;
    flex-direction: row;
    width: 60vw;
    height: 80vh;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 10vh; 
    text-align: left; 
  }

  .full-screen-div-content>video {
    /* Wideo po lewej, na sztywno 30vw */
    width: 30vw;
    height: 100%;
    object-fit: cover;
  }

  /* Przycisk "cofnij" */
  .back-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top:3%;
    right: 47%;
  }
  .back-button:hover {
    background-color: #45a049;
  }

  /*
    Ukrywamy bottom-button na desktopie,
    bo wszystko widzimy obok wideo
  */
  .bottom-button {
    display: none;
  }

  /*
    orange-div po prawej, 50vw
    w środku 2 kolumny z produktami
  */
  .orange-div {
    width: 50vw;
    height: 100%;
    background-color: #f9f9f9;
    overflow-y: auto;
    position: relative;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    padding: 10px;
    /* 
      Nie musimy tu robić grid, 
      bo przyjmuję, że masz "product-grid-item" 
      w środku orange-div, do której damy grid 2 kolumn.
    */
  }

  /* 
    Dla elementu z produktami: 2 w wierszu
    np. <div class="product-grid-item"> ... </div> wewnątrz orange-div
  */
  .orange-div .product-grid-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 kolumny */
    gap: 10px; 
  }

  /* Pojedynczy kafelek produktu */
  .orange-div .product-grid-item .link-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
    overflow: hidden;
    text-align: center;
  }
  .orange-div .product-grid-item .link-container>img {
    width: 100%;
    height: auto;
    display: block;
  }
  .orange-div .product-grid-item .link-text {
    color: #213801;
    font-size: 120%;
    font-weight: bold;
    padding: 8px;
  }

  

  .socialsIcons{
    height:20vh;
    width: 40px;
    position:absolute;
    top:0px;
    right: 6px;
  }
  .socialIcon{
    height: calc(20vh / 4 - 7px);
    width: calc(20vh / 4 - 7px);
    margin: 3px 0 0 3px;
  }
  .play-pause-icon{
    font-size: 200px;
    position: absolute;
    margin-top: 20vh;
    margin-left: 6vw;
  }
  .product-info{
    text-align: center;
    text-justify: center;
  }
  .spinner-container {
    position: fixed;
    bottom: 20px;      /* 20px od dołu ekranu */
    left: 50%;         /* wyśrodkuj w poziomie */
    transform: translateX(-50%); 
    z-index: 9999;     /* żeby było ponad innymi elementami */
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;        /* kolor obwódki */
    border-top-color: #28a1e5;     /* kolor części obręczy */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .active-category {
    border: 5px solid #FFC107; /* albo inny kolor */
    /* Możesz dać background, np. background-color: #FFFACD; */
  }
  
}
